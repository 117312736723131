import styled from 'styled-components';

export const FooterStyle = styled.div`
  width: 100%;
  height: 5%;
  flex-direction: column;
  background-color: #0A5987;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  left: 0;
  border-top: 1px #01436A solid;
  border-top: 5px solid #3AA25F;
`;

export const Text = styled.p`
  font-family: 'Rawline', sans-serif;
  font-size: 80%;
  margin:0;
  color: #C8C8C8;
  font-weight: 600;
`;
