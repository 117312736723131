import axios from "axios";
import IHttpClientServices from "./IHttpClientServices";

type Usuario = {
    id: string;
    nome: string;
    email: string;
    senha: string;
    cpf: string;
    telefone: string;
    setor: string;
    role: string;
    permissoes: {
        nome: string;
        programaDirec: string;
        programaDrae: string;
        programaEscola: string;
    }[];
}

export default class UsuarioService implements IHttpClientServices {
    token: string | null;
    url: string | null;
    headers: { authorization: string };

    constructor() {
        this.url = `${process.env.REACT_APP_FEE}/user` || null;

        const dados = sessionStorage.getItem("dados");

        this.token = dados ? JSON.parse(dados).usuario.token : null;
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
    }

    listar(): Promise<any[]> {
        throw new Error("Method not implemented.");
    }
    buscarPorId(id: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    criar(obj: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    atualizarPorId(id: string, obj: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    deletarPorId(id: string): Promise<any> {
        throw new Error("Method not implemented.");
    }

    async listarUsuarios(): Promise<Usuario[]> {
        if (this.token === null) {
            alert("Token não encontrado");
            return []
        };

        const response = await axios.get(`${this.url}/listarUsuariosAtivos`);
        return response.data;
    }
}