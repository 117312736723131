import React, { useState, useContext, createContext } from "react";
import { verificarPermissoesDirec } from "./DirecPermissoesCheck";
import { verificarPermissoesDrae } from "./DraePermissoesCheck";
import {
    verificarPermissoesEscola
} from "./EscolaPermissoesCheck";
//criando o context
export const MyPermissoesContext = createContext({
    permissoes: [],
    direcsPermitidas: [],
    draesPermitidas: [],
    escolasPermitidas: [],
    EscolasPaguePredial: [],
    EscolasPagueOrdinario: [],
    EscolasMaisAlimentacao: [],
    apagarTodasAsPermissoes: () => { },
    verificarPermissoesDoUsuario: () => { },
    setDraesPermitidas: () => { },
    setDirecsPermitidas: () => { },
    setEscolasPermitidas: () => { }
});

//utilizar o context
export const useMyPermissoesContext = () => {
    return useContext(MyPermissoesContext);
};

//envolver o objeto no context
export function MyPermissoesContextProvider({ children }) {
    const [permissoes, setPermissoes] = useState([]);
    const [direcsPermitidas, setDirecsPermitidas] = useState([])
    const [draesPermitidas, setDraesPermitidas] = useState([])
    const [escolasPermitidas, setEscolasPermitidas] = useState([]);
    const [EscolasPaguePredial, setEscolasPaguePredial] = useState([]);
    const [EscolasPagueOrdinario, setEscolasPagueOrdinario] = useState([]);
    const [EscolasMaisAlimentacao, setEscolasMaisAlimentacao] = useState([]);


    const verificarPermissoesDoUsuario = async (dados) => {
        if (dados.usuario.permissoes) {
            if (dados.relatorio === true) {
                setPermissoes([...permissoes, "RELATORIO"])
            }
            if (dados.role === "ADMIN") {
                setPermissoes([...permissoes, "ADMIN"])
            }

            dados.usuario.permissoes.forEach((permissao) => {
                setPermissoes([...permissoes, permissao.nome])

                if (permissao.programaDirec !== null && permissao.programaDirec !== "") {
                    verificarPermissoesDirec(dados, permissao.nome, permissao.programaDirec, dados.usuario.direc).then((res) => {
                        setDirecsPermitidas(res);
                    });
                }

                if (permissao.programaDrae !== null && permissao.programaDrae !== "" ) {
                    verificarPermissoesDrae(dados, permissao.nome, permissao.programaDrae, dados.usuario.drae).then((res) => {

                        setDraesPermitidas(res)
                    })
                }

                if (permissao.programaEscola !== null && permissao.programaEscola !== "") {
                    verificarPermissoesEscola(dados, permissao.nome, permissao.programaEscola, dados.usuario.escola, dados.usuario.direc, dados.usuario.drae).then((res) => {
                        setEscolasPermitidas(res);
                    })
                }
            })
        } else {
            setPermissoes([])
            setDirecsPermitidas([])
            setDraesPermitidas([])
            setEscolasPermitidas([])
        }
    }

    function apagarTodasAsPermissoes() {
        setPermissoes([])
        setDirecsPermitidas([])
        setDraesPermitidas([])
        setEscolasPermitidas([])
        setEscolasPaguePredial([])
        setEscolasPagueOrdinario([])
        setEscolasMaisAlimentacao([])
    }
    return (
        <MyPermissoesContext.Provider
            value={{
                verificarPermissoesDoUsuario, permissoes,
                direcsPermitidas, draesPermitidas, escolasPermitidas,
                EscolasPaguePredial, EscolasPagueOrdinario,
                EscolasMaisAlimentacao, apagarTodasAsPermissoes,
                setDirecsPermitidas, setDraesPermitidas, setEscolasPermitidas
            }}
        >
            {children}
        </MyPermissoesContext.Provider>
    );
}
