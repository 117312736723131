import styled from "styled-components";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { MdCalendarToday } from "react-icons/md";

import React, { useId, forwardRef } from 'react';

import Select from 'react-select';

export const H1 = styled.h1`
text-align: center;
width: 100%;
height: auto;
font-weight: bold;
font-size: 1.3rem;
padding: 1% 0 1% 0;
`;

export const Label = styled.label`
font-size: 18px;
color: #000000;
width: 100%;
font-weight: 600;
text-align: left;
margin-bottom: 2px;
`;

export const HelperText = styled.label`
color: red;
font-size: 0.8rem;
margin-top: 0.5rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  width: fit-content;
  height: fit-content;
`;

const StyledDatePicker = styled(DatePicker)`
  border: none;
  border-bottom: 1px solid #ced4da;
  color: #A9A9A9;
  display: flex;
  flex-shrink: 0;
  width: 130%;
  background-color: transparent;
  &:focus {
    border: none;
    border-bottom: 1px solid gray;
    outline: none;
  }
  &::placeholder {
    color: #272727;
    font-size: small;
  }
`;

export const DateLabeled = forwardRef(({ label, date, onChange, selected, placeholder, ...props }, ref) => {
  const inputId = useId();

  return (
    <>
      <Container>
        <Label htmlFor={inputId}>{label}</Label>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          color: '#9E9E9E',
          alignItems: 'center',
          gap: '5px',
        }}>
          <MdCalendarToday />
          <StyledDatePicker
            className="my-datepicker"
            ref={ref}
            id={inputId}
            onChange={onChange}
            selected={date}
            dateFormat="dd/MM/yyyy"
            placeholderText={placeholder}
            {...props}
          />
        </div>
      </Container>
    </>
  );
});

export const LabeledSelect = forwardRef(({ label, type, name, ...props }, ref) => {
  const inputId = useId();

  return (
    <>
      <Container>
        <Label htmlFor={inputId}>{label}</Label>
        <Select ref={ref} id={inputId} type={type} name={name} {...props} />
      </Container>
    </>
  );
});

export const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #202020;
  display: flex;
  position: sticky;
  font-size: 16px;
  color: #A9A9A9;
  transition: 2s;
  height: 100%;
    
    &:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid #0A5987;
  }
    &::placeholder {
    color: #A9A9A9;
    font-size: small;
    align-items: start;
  }
`;

export const LabeledInput = forwardRef(({ label, type, name, ...props }, ref) => {
  const inputId = useId();

  return (
    <>
      <Container>
        <Label htmlFor={inputId}>{label}</Label>
        <Input ref={ref} id={inputId} type={type} name={name} {...props} />
      </Container>
    </>
  );
});

export const Button = styled.button`
width: ${props => props.width ? props.width : '80%'};
height: ${props => props.height ? props.height : '35%'};
font-size: 16px;
min-width: 50px;
min-height: 35px;
font-style: normal;
text-align: center;
font-weight: 100;
border: 1px solid #0a7bbd;
font-weight: bolder;
justify-content: center;
font-family: "Rawline", sans serif;
align-items: center;
flex-shrink: 0;
transition: 0.5s;
margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0%'};

  &:hover {
  box-shadow: 0px 0px 3px 0px #cacbcd;
  text-shadow: #fbfbfb 0px 0px 10px;
  background-color: ${props => props.primary ? `#fff` : `#fff`};
  color: ${props => props.primary ? `#000000` : `#000000`};
  border:  ${props => props.primary ? `#e9e9e9` : `#000000`};
  cursor: pointer;
}

color: ${props => props.selected ? '#ffffff' : (props.primary ? `#0a7bbd` : `#fff`)};
background-color: ${props => props.selected ? '#0a7bbd' : (props.primary ? `#fff` : `#0a7bbd`)};
`;
