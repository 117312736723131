import axios from "axios";

export async function verificarPermissoesEscola(
  dados,
  nomePermissao,
  contasPermissao,
  escola,
  direc,
  drae
) {
  let escolasRetornadas = [];

  let programaEscola = dados.usuario.permissoes[0].programaEscola;

  let escolasPaguePredial = [];
  let escolasPagueOrdinario = [];
  let escolasMaisAlimentação = [];

  if (programaEscola !== null || programaEscola !== "") {
    if (programaEscola.includes("pp")) {
      if (nomePermissao === "CORE") {
        escolasPaguePredial = await buscarEscolasPorPrograma(
          dados,
          "PAGUE PREDIAL 000097"
        );
      } else if (nomePermissao === "DIREC") {
        escolasPaguePredial = await buscarEscolaPorProgramaDirec(
          dados,
          direc,
          "PAGUE PREDIAL 000097"
        );
      }
    }

    if (programaEscola.includes("po")) {
      if (nomePermissao === "CORE") {
        escolasPagueOrdinario = await buscarEscolasPorPrograma(
          dados,
          "PAGUE ORDINARIO 000001"
        );
      } else if (nomePermissao === "DIREC") {
        escolasPagueOrdinario = await buscarEscolaPorProgramaDirec(
          dados,
          direc,
          "PAGUE ORDINARIO 000001"
        );
      }
    }

    if (programaEscola.includes("ma")) {
      if (nomePermissao === "DRAE") {
        escolasMaisAlimentação = await buscarEscolaPorProgramaDrae(
          dados,
          drae,
          "PAGUE MAIS ALIMENTACAO 000098"
        );
      } else if (nomePermissao === "SUASE") {
        escolasMaisAlimentação = await buscarEscolasPorPrograma(
          dados,
          "PAGUE MAIS ALIMENTACAO 000098"
        );
      }

      if (nomePermissao === "ADMIN" || nomePermissao === "FEE") {
        let todas = await buscarEscolas(dados);
        return todas;
      }
    }

    escolasRetornadas = escolasPaguePredial.concat(
      escolasPagueOrdinario,
      escolasMaisAlimentação
    );

    return escolasRetornadas;
  } else {
    return null;
  }
}

async function buscarEscolaPorProgramaDrae(dados, draeId, convenio) {
  return axios
    .get(
      `${process.env.REACT_APP_FEE}/escola/buscarEscolasPorConvenioDrae/${draeId}/${convenio}`,
      {
        headers: { authorization: `Bearer ${dados.usuario.token}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarEscolaPorProgramaDirec(dados, direcId, convenio) {
  return axios
    .get(
      `${process.env.REACT_APP_FEE}/escola/buscarEscolasPorConvenioDirec/${direcId}/${convenio}`,
      {
        headers: { authorization: `Bearer ${dados.usuario.token}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarEscolasPorPrograma(dados, convenio) {
  return axios
    .get(
      `${process.env.REACT_APP_FEE}/escola/buscarEscolasPorConvenio/${convenio}/`,
      {
        headers: { authorization: `Bearer ${dados.usuario.token}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarEscolas(dados) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/escola/buscarAtivos`, {
      headers: { authorization: `Bearer ${dados.usuario.token}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.error(error);
    });
}
