import styled, { keyframes } from 'styled-components';

const entryButton = keyframes`
0% {
      transform: translateX(-50%);
    }
    100%{
      transform: translateX(0);
    }
`;

export const IconMenu = styled.div`
color:#0A7BBD;
transition: 0.4s;
`;

export const ButtonStyle = styled.button`
  background-color: ${props => props.isSelected ? "transparent" : "transparent"};
  box-shadow: ${props => props.isSelected ? "18px 0px 15px #7c878d42 inset" : "none"};
  box-shadow: ${props => props.isSelected ? "-2px 0px -2px white inset" : "none"};
  color: ${props => props.isSelected ? "#0a7bbd" : "#272727"};
  border:none;
  font-family: Rawline, sans-serif;
  font-weight: ${props => props.isSelected ? "600" : "500"};;
  padding: 10% 0;
  display: flex;
  transition: 0.4s;
  width: 100%;
  align-items: center;
  justify-content: center;
  animation: ${entryButton} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
 
  &:hover {
    color: #0a7bbd;
    cursor: pointer;
    border-radius: 4%;
    background-color: #f2f2f2;

    ${IconMenu}{
      transform: translateX(20%);
    }

  }

  &:active {
    color: #02336b;
  }
   
   &:focus {
    outline: none;
  }
`;

export const AlignButton = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  transition: all 0.1s ease-in-out;
  gap: 10%;
  width: 85%;

  &:hover {
    color: #0a7bbd;
  }
`;