import React, { useEffect } from "react";
import { useState } from "react";
import * as S from "./formConsulta.style";
import { DateLabeled, Button } from "../globalComponents.style";
import Select from "react-select";
import axios from "axios";
import Extrato from "../../components/Extrato/extrato";
import ExtratoFI from "../../components/ExtratoFundoInvestimento/extratoFundoInvestimento";
import { Spinner } from "react-bootstrap";

import PdfContaCorrente from "../../reports/extrato";
import PdfFI from "../../reports/extratoAplicacaoFinanceira";
import { IoPrintSharp } from "react-icons/io5";
import { toast } from "sonner";

//funcao para obter o ultimo dia do mes atual
function ultimoDiaDoMes(ano, mes) {
  // Cria uma instância de data para o primeiro dia do próximo mês
  const primeiroDiaProximoMes = new Date(ano, mes, 1);
  // Subtrai um dia para obter o último dia do mês atual
  const ultimoDiaMesAtual = new Date(primeiroDiaProximoMes - 1);
  return ultimoDiaMesAtual.getDate();
}

//funcao para obter o primeiro dia do mes seguinte
function primeiroDiaDoMes(ano, mes) {
  // Cria uma instância de data para o primeiro dia do mês
  const primeiroDia = new Date(ano, mes, 1);
  return primeiroDia.getDate();
}

// funcao para consultar o ExtratoOrgaoRepasseProgramaDoGoverno"
async function ExtratoProgramaGoverno(
  dataInicial,
  dataFinal,
  operacao,
  agencia,
  conta,
  token
) {
  const diaInicio = dataInicial.getDate(); // Obtém o dia do mês para dataIncio
  // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
  const diaInicioFormatado = diaInicio < 10 ? `0${diaInicio}` : diaInicio;

  const diaFim = dataFinal.getDate(); // Obtém o dia do mês para dataFim
  // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
  const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

  const mesInicio = dataInicial.getMonth() + 1; // Obtém o  mês para dataIncio
  // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
  const mesInicioFormatado = mesInicio < 10 ? `0${mesInicio}` : mesInicio;
  // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
  const mesFim = dataFinal.getMonth() + 1; // Obtém o mês para dataFim
  // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
  const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;
  if (mesFim > mesInicio) {
    if (30 - diaInicio + diaFim < 32) {
      let data = JSON.stringify({
        agencia: agencia.replace(/.$/, ""),
        conta: conta.replace(/.$/, ""),
        dataIncio:
          dataInicial.getFullYear() +
          "-" +
          mesInicioFormatado +
          "-" +
          diaInicioFormatado,
        dataFim:
          dataFinal.getFullYear() +
          "-" +
          mesFimFormatado +
          "-" +
          diaFimFormatado,
      });

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_FEE}/bb/${operacao}/`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };
      const resp = await axios(config)
        .then(function (response) {
          return JSON.stringify(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });

      if (resp) {
        return [JSON.parse(resp), true, true];
      }
    } else {
      let intervalo = mesFim - mesInicio;
      let primeiro = true;
      var resposta = {};
      let i = 0;
      for (; intervalo > 0; intervalo--, ++i) {
        if (primeiro) {
          primeiro = false;
          const diaInicio = dataInicial.getDate(); // Obtém o dia do mês para dataIncio
          // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
          const diaInicioFormatado =
            diaInicio < 10 ? `0${diaInicio}` : diaInicio;
          const mesInicio = dataInicial.getMonth() + 1; // Obtém o  mês para dataIncio
          // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
          const mesInicioFormatado =
            mesInicio < 10 ? `0${mesInicio}` : mesInicio;

          const diaFim = ultimoDiaDoMes(dataInicial.getFullYear(), mesInicio); // Obtém o dia do mês para dataFim
          // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
          const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

          // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
          const mesFim = mesInicio; // Obtém o mês para dataFim
          // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
          const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;

          let data = JSON.stringify({
            agencia: agencia.replace(/.$/, ""),
            conta: conta.replace(/.$/, ""),
            dataIncio:
              dataInicial.getFullYear() +
              "-" +
              mesInicioFormatado +
              "-" +
              diaInicioFormatado,
            dataFim:
              dataFinal.getFullYear() +
              "-" +
              mesFimFormatado +
              "-" +
              diaFimFormatado,
          });

          let config = {
            method: "post",
            url: `${process.env.REACT_APP_FEE}/bb/${operacao}/`,
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          const resp = await axios(config)
            .then(function (response) {
              return JSON.stringify(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });

          if (resp && JSON.parse(resp).data !== "sem dados") {
            resposta = JSON.parse(resp);
          }
        }
        if (intervalo === 1) {

          const diaInicio = primeiroDiaDoMes(
            dataFinal.getFullYear(),
            dataFinal.getMonth()
          ); // Obtém o dia do mês para dataIncio
          // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda

          const diaInicioFormatado =
            diaInicio < 10 ? `0${diaInicio}` : diaInicio;
          const mesInicio = dataFinal.getMonth() + 1; // Obtém o  mês para dataIncio
          // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
          const mesInicioFormatado =
            mesInicio < 10 ? `0${mesInicio}` : mesInicio;

          const diaFim = dataFinal.getDate(); // Obtém o dia do mês para dataFim
          // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
          const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

          // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
          const mesFim = dataFinal.getMonth() + 1; // Obtém o mês para dataFim
          // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
          const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;

          let data = JSON.stringify({
            agencia: agencia.replace(/.$/, ""),
            conta: conta.replace(/.$/, ""),
            dataIncio:
              dataInicial.getFullYear() +
              "-" +
              mesInicioFormatado +
              "-" +
              diaInicioFormatado,
            dataFim:
              dataFinal.getFullYear() +
              "-" +
              mesFimFormatado +
              "-" +
              diaFimFormatado,
          });

          let config = {
            method: "post",
            url: `${process.env.REACT_APP_FEE}/bb/${operacao}/`,
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };

          const resp = await axios(config)
            .then(function (response) {
              return JSON.stringify(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });

          if (resp && JSON.parse(resp).data !== "sem dados") {
            let valor = JSON.parse(resp);
            if (Object.keys(resposta).length === 0) {
              resposta = JSON.parse(resp);
            } else {
              resposta.transactions.push(...valor.transactions);
            }
            return [resposta, true, true];
          }
          if (Object.keys(resposta).length === 0) {
            console.log(resp);
            resposta = JSON.parse(resp);
            return [resposta, true, true];
          }
          return [resposta, true, true];
        } else {
          const diaInicio = primeiroDiaDoMes(
            dataInicial.getFullYear(),
            dataInicial.getMonth() + i
          ); // Obtém o dia do mês para dataIncio
          // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
          const diaInicioFormatado =
            diaInicio < 10 ? `0${diaInicio}` : diaInicio;

          const mesInicio = dataInicial.getMonth() + 2 + i; // Obtém o  mês para dataIncio
          // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
          const mesInicioFormatado =
            mesInicio < 10 ? `0${mesInicio}` : mesInicio;

          const diaFim = ultimoDiaDoMes(
            dataInicial.getFullYear(),
            dataInicial.getMonth() + 2 + i
          ); // Obtém o dia do mês para dataFim
          // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
          const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

          // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
          const mesFim = dataInicial.getMonth() + 2 + i; // Obtém o mês para dataFim
          // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
          const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;

          let data = JSON.stringify({
            agencia: agencia.replace(/.$/, ""),
            conta: conta.replace(/.$/, ""),
            dataIncio:
              dataInicial.getFullYear() +
              "-" +
              mesInicioFormatado +
              "-" +
              diaInicioFormatado,
            dataFim:
              dataFinal.getFullYear() +
              "-" +
              mesFimFormatado +
              "-" +
              diaFimFormatado,
          });
          let config = {
            method: "post",
            url: `${process.env.REACT_APP_FEE}/bb/${operacao}/`,
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };

          const resp = await axios(config)
            .then(function (response) {
              return JSON.stringify(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });

          if (resp && JSON.parse(resp).data !== "sem dados") {
            let valor = JSON.parse(resp);
            if (Object.keys(resposta).length === 0) {
              resposta = JSON.parse(resp);
            } else {
              resposta.transactions.push(...valor.transactions);
            }
          }
        }
      }
    }
  }
}

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: '5%',
    width: '100%',
    border: "none",
    borderBottom: "1px solid #ced4da",
    borderRadius: "0.25rem",
    backgroundColor: "transparent",
    "&:hover": {
      borderColor: "#80bdff",
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 480,
    fontSize: 14,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "yellow" : "blue",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

//obeto comtendo as opções das operações realizadas
const optionsOperacao = [
  {
    value: "ExtratoOrgaoRepasseProgramaDoGoverno",
    label: "Extrato Orgao Repasse Programa Do Governo",
  },
  // {
  //   value: "ExtratoPoupancaOrgaoRepasse",
  //   label: "Extrato Poupanca Orgao Repasse",
  // },
  {
    value: "ExtratoFundosInvestimentoOrgaoRepasse",
    label: "Extrato Fundos Investimento Orgao Repasse",
  },
];

const FormConsulta = (props) => {
  const [selectedOption, setSelectedOption] = useState("none");
  const [options, setOptions] = useState([]);
  const [loadedEntidade, setLoadedEntidade] = useState(true);
  const [selectedOptionOperacao, setSelectedOptionOperacao] = useState("none");
  // eslint-disable-next-line
  const [entidade, setEntidade] = useState({});
  const [saldoContaCorrente, setSaldoContaCorrente] = useState("none");
  const [saldoAplicacao, setSaldoAplicacao] = useState("none");
  // eslint-disable-next-line
  const [flag, setFlag] = useState(false);
  // eslint-disable-next-line
  const [flagExtrato, setFlagExtrato] = useState(false);
  const [dados, setDados] = useState({});
  const [dadosFI, setDadosFI] = useState([]);
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [loading, setLoading] = useState(false);
  const [primeiroMes, setPrimeiroMes] = useState(null);
  const [ultimoMes, setUltimoMes] = useState(null);

  const token = JSON.parse(sessionStorage.getItem("dados")).usuario.token;
  //carrega lista de entidade ao inicializar o componente
  if ((Array.isArray(props.entidades) && loadedEntidade)) {
    const newOptions = props.entidades.map((res, index) => ({
      value: res,
      label: `${res.cnpj} - ${res.nome} - ${res.convenio}`,
      key: index,
    }));
    setOptions(newOptions);
    setLoadedEntidade(false);
  }
  
  const consultarSaldoAplicaçãoFinanceira = async () => {
    const { agencia, conta } = selectedOption;

    let data = JSON.stringify({
      agencia: agencia?.replace(/.$/, ""),
      conta: conta?.replace(/.$/, ""),
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_FEE}/bb/SaldoAplicacaoFinanceira/`,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const valor = await axios(config)
      .then(function (response) {
        return JSON.stringify(response.data);
      })
      .catch(function (error) {
        toast.error("Erro ao consultar saldo de aplicação financeira");
        if (error.response.status == 400) {
        }
      }
      );

    if (valor) {
      setSaldoAplicacao(JSON.parse(valor));
      setFlag(true);
    }
  };

  const consultarContaCorrente = async () => {
    const { agencia, conta } = selectedOption;

    let data = JSON.stringify({
      agencia: agencia?.replace(/.$/, ""),
      conta: conta?.replace(/.$/, ""),
    });

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_FEE}/bb/SaldoContaCorrente/`,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const valor = await axios(config)
      .then(function (response) {
        return JSON.stringify(response.data);
      })
      .catch(function (error) {
        toast.error("Erro ao consultar saldo de aplicação financeira");
        if (error.response.status == 400) {
        }
      });

    if (valor) {
      setSaldoContaCorrente(JSON.parse(valor));
      setFlag(true);
    }
  };

  const realizarConsulta = () => {
    if (!dataInicial || dataFinal === "") {
      toast.error("Preencha todos os campos");
      return
    }
    setSaldoAplicacao("none");
    setSaldoContaCorrente("none");
    setDados({});
    setLoading(true);
    //consultar conta corrente
    consultarContaCorrente();
    consultarSaldoAplicaçãoFinanceira();

    //função para fazer requisao no backend
    setFlag(false);
    props.entidades.forEach(async (res) => {
      if (
        res.cnpj === selectedOption.cnpj &&
        res.convenio === selectedOption.convenio
      ) {
        setEntidade(res);
        const { agencia, conta } = res;

        if (selectedOptionOperacao === "ExtratoPoupancaOrgaoRepasse") {
          let data = JSON.stringify({
            agencia: agencia.replace(/.$/, ""),
            conta: conta.replace(/.$/, ""),
            variacao: 51,
            mes: dataInicial.getMonth() + 1,
            ano: dataInicial.getFullYear(),
          });

          let config = {
            method: "post",
            url: `${process.env.REACT_APP_FEE}/bb/${selectedOptionOperacao}/`,
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };

          const resp = await axios(config)
            .then(function (response) {
              return JSON.stringify(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });
          if (resp) {
            // setDados(JSON.parse(resp));
            // setFlag(true);
            // setFlagExtrato(true);
          }
          console.log(resp);
        }

        if (selectedOptionOperacao === "ExtratoOrgaoRepasseProgramaDoGoverno") {
          const diaInicio = dataInicial.getDate(); // Obtém o dia do mês para dataIncio
          // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
          const diaInicioFormatado =
            diaInicio < 10 ? `0${diaInicio}` : diaInicio;

          const diaFim = dataFinal.getDate(); // Obtém o dia do mês para dataFim
          // Verifica se o dia é menor que 10 e adiciona um "0" à esquerda
          const diaFimFormatado = diaFim < 10 ? `0${diaFim}` : diaFim;

          const mesInicio = dataInicial.getMonth() + 1; // Obtém o  mês para dataIncio
          // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
          const mesInicioFormatado =
            mesInicio < 10 ? `0${mesInicio}` : mesInicio;
          // Verifica se o mês menor que 10 e adiciona um "0" à esquerda
          const mesFim = dataFinal.getMonth() + 1; // Obtém o mês para dataFim
          // Verifica se o mês é menor que 10 e adiciona um "0" à esquerda
          const mesFimFormatado = mesFim < 10 ? `0${mesFim}` : mesFim;

          if (mesFim > mesInicio) {
            const [data, flag1, flag2] = await ExtratoProgramaGoverno(
              dataInicial,
              dataFinal,
              selectedOptionOperacao,
              agencia,
              conta,
              token
            );
            setDados(data);
            setFlag(flag1);
            setFlagExtrato(flag2);
          } else {
            let data = JSON.stringify({
              agencia: agencia?.replace(/.$/, ""),
              conta: conta?.replace(/.$/, ""),
              dataIncio:
                dataInicial.getFullYear() +
                "-" +
                mesInicioFormatado +
                "-" +
                diaInicioFormatado,
              dataFim:
                dataFinal.getFullYear() +
                "-" +
                mesFimFormatado +
                "-" +
                diaFimFormatado,
            });

            let config = {
              method: "post",
              url: `${process.env.REACT_APP_FEE}/bb/${selectedOptionOperacao}/`,
              headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              data: data,
            };
            const resp = await axios(config)
              .then(function (response) {
                return JSON.stringify(response.data);
              })
              .catch(function (error) {
                console.log(error);
              });

            if (resp) {
              setDados(JSON.parse(resp));
              setFlag(true);
              setFlagExtrato(true);
            }
          }
        }
        setFlag(false);

        if (
          selectedOptionOperacao === "ExtratoFundosInvestimentoOrgaoRepasse"
        ) {
          setDadosFI([]);
          setPrimeiroMes(dataInicial.getMonth() + 1);
          setUltimoMes(dataFinal.getMonth() + 1);
          let resposta = [];

          const extrairDados = async (mes) => {
            const data = JSON.stringify({
              agencia: agencia.replace(/.$/, ""),
              conta: conta.replace(/.$/, ""),
              codFI: 1972,
              mes,
              ano: dataInicial.getFullYear(),
            });

            const config = {
              method: "post",
              url: `${process.env.REACT_APP_FEE}/bb/${selectedOptionOperacao}/`,
              headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              data: data,
            };

            try {
              const response = await axios(config);
              return response;
              // setFlagExtrato(true);
            } catch (error) {
              console.log(error);
            }
          };

          if (dataFinal.getMonth() + 1 > dataInicial.getMonth() + 1) {
            for (
              let i = 0;
              dataInicial.getMonth() + 1 + i <= dataFinal.getMonth() + 1;
              i++
            ) {
              resposta.push(await extrairDados(dataInicial.getMonth() + 1 + i));
            }
            setDadosFI(resposta);
          } else {
            resposta.push(await extrairDados(dataInicial.getMonth() + 1));
            setDadosFI(resposta);
          }
        }
      }
    });
  };

  return (
    <>
      <S.ContainerFormConsulta>
        <S.Form
          onSubmit={(e) => {
            e.preventDefault();
            realizarConsulta({
              cnpj: selectedOption,
              operacao: selectedOptionOperacao,
              dataInicial,
              dataFinal,
            });
          }}
        >
          <S.CamposObrigatorio>
            <div style={{ width: "100%" }}>
              <S.LabelTitle>CNPJ</S.LabelTitle>
              <Select
                styles={customStyles}
                options={options}
                placeholder="Selecione um CNPJ"
                onChange={(option) => setSelectedOption(option.value)}
                value={options.find((option) => option.value === selectedOption)}
              />
              <S.LabelTitle style={{ marginBottom: "3px" }}>
                OPERAÇÃO
              </S.LabelTitle>
              <Select
                styles={customStyles}
                options={optionsOperacao}
                placeholder="Selecione uma operação"
                onChange={(option) => setSelectedOptionOperacao(option.value)}
                value={optionsOperacao.find(
                  (option) => option.value === selectedOptionOperacao
                )}
              /></div>
          </S.CamposObrigatorio>

          <S.CamposObrigatorio>
            <div style={{ width: "100%", alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
              <div>
                <S.LabelTitle>PERÍODO</S.LabelTitle>
                <div
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <DateLabeled
                    id="dataInicial"
                    selected={dataInicial}
                    date={dataInicial}
                    placeholder="Data inicial"
                    onChange={(date) => setDataInicial(date)}
                  />
                  <DateLabeled
                    id="dataFinal"
                    selected={dataFinal}
                    date={dataFinal}
                    placeholder="Data final"
                    onChange={(date) => setDataFinal(date)}
                    minDate={dataInicial}
                  />
                </div>
              </div>
              <div style={{ width: "100%", display: "flex", marginTop: "1%", justifyContent: "center" }}>
                <Button width="50%" height="5%" type="submit">
                  Consultar
                </Button>
              </div>
            </div>
          </S.CamposObrigatorio>
        </S.Form>


        <S.ContainerSaldo>
          <S.LabelSaldo
            titulo="Saldo Conta Corrente:"
            valor={saldoContaCorrente.valorDisponibilidade}
            loading={loading}
          />
          <S.LabelSaldo
            titulo="Saldo Aplicação:"
            valor={saldoAplicacao.valorDisponibilidade}
            loading={loading}
          />

          <Button
            primary
            width="50%"
            height="20%"
            onClick={() =>

              selectedOptionOperacao === "ExtratoFundosInvestimentoOrgaoRepasse"
                ? PdfFI({ dadosFI, entidade }) : selectedOptionOperacao === "ExtratoOrgaoRepasseProgramaDoGoverno"
                  ? PdfContaCorrente({ dados, entidade }) : toast.warning('Realize uma consulta')
            }
            marginBottom="2%"
          >
            <IoPrintSharp /> Imprimir
          </Button>
        </S.ContainerSaldo>
      </S.ContainerFormConsulta >
      <S.ContainerFormConsulta2
      >
        {loading ? (
          flag ? (
            dadosFI.length > 0 ? (
              <ExtratoFI
                data={dadosFI}
                mesIncio={primeiroMes}
                mesFim={ultimoMes}
              />
            ) : (
              <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                <div class="spinner-border" role="status" />

              </div>
            )
          ) : Object.keys(dados).length > 0 ? (
            <Extrato data={dados} />
          ) : (
            <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
              <div class="spinner-border" role="status" />

            </div>
          )
        ) : (
          false
        )}
      </S.ContainerFormConsulta2>
    </>
  );
};

export default FormConsulta;
