import styled from "styled-components";

export const HeaderStyle = styled.div`
  width: ${props => props.esconderMenu ? "85vw" : "95vw"};
  transition: all 0.5s ease-in-out;
  height: 6vh;
  padding-top: 0.2%;
  justify-content: center;
  display: flex;
  box-sizing: border-box;
  position: relative;
  font-family: "Rawline", sans-serif;
  align-items: center;
  flex-direction: row;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 1.3rem;
  display: flex;
  color: #373737;
  text-align: center;
  margin-bottom: 5px;
`;
