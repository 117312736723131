import React, { useState } from "react";
import "./style/form.css";
import { Button } from "../../components/globalComponents.style.jsx";
import { Usuario } from "./tables/Table_Usuario.jsx";
import { Permissao } from "./tables/Table_Permissao.jsx";
import Direc from "./tables/Table_Direc.jsx";
import Escola from "./tables/Table_Escola.jsx";
import Drae from "./tables/Table_Drae.jsx";

const buttons = [
  { label: 'Direcs', value: 1, component: Direc },
  { label: 'Draes', value: 2, component: Drae },
  { label: 'Escolas', value: 3, component: Escola },
  { label: 'Usuários', value: 4, component: Usuario },
  { label: 'Permissões', value: 5, component: Permissao },
];

export default function Admin() {
  const [flag, setFlag] = useState(1);

  function handleClick(value) {
    setFlag(value);
  }

  function renderComponent() {
    switch (flag) {
      case 1:
        return <Direc />
      case 2:
        return <Drae />
      case 3:
        return <Escola />
      case 4:
        return <Usuario />;
      case 5:
        return <Permissao />;
      default:
        return null;
    }
  }

  return (
    <div className="admin-container">
      <div className="button-container">
        {buttons.map(button => (
          <Button
            key={button.value}
            width="18%"
            height="50%"
            primary
            selected={flag === button.value}
            onClick={() => handleClick(button.value)}
          >
            {button.label}
          </Button>
        ))}
      </div>
      <div className="table-container">
        {renderComponent()}
      </div>
    </div>
  );
}