import styled from "styled-components";

import { keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  100% {
    background-color: #6b5252;
  }
`;


export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  direction: column;
  position: relative;
  overflow: hidden;
  font-family: "Rawline", sans-serif;
`;

export const BodyStyle = styled.div`
  position: absolute;
  width: 85%;
  height: 100%;
  padding-bottom: 10%;
  display: flex;
  margin-left: 15vw;
  margin-top: 4.2%;
  background-color: white;
  flex-direction: column;
`;

export const TableBoxContainer = styled.div`
 display: flex;
 position: relative;
 width: 100%;
 overflow-x: hidden;
 height: 100%;
 `;

export const TableContainer = styled.div` 
flex-direction: column;
align-items: center;
height: auto;
width: 100%;
`;
