import React, { useEffect, useState } from "react";
import * as T from "./table.style";
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import { FaArrowDownZA, FaArrowUpZA } from "react-icons/fa6";
import { GiModernCity } from "react-icons/gi";

function Table({
  opcao,
  dadosConsultados,
  getTotalContaCorrente,
  getTotalAplicacao,
  filteredEscolas,
  filteredDirecs,
  filteredDraes,
  dadosEmTela,
  setDadosEmTela
}) {
  // ordena os dados consultados de acordo com a opção escolhida
  // caso seja escolhida a opção 2, 3 ou 4, os dados são ordenados de acordo com a direc
  // caso seja escolhida a opção 0 ou 1, os dados são ordenados de acordo com o nome por ordem alfabética
  const [saldoTotalOpcao, setSaldoTotalOpcao] = useState(0);
  const [ordemAZNomeOpcao, setOrdemAZNomeOpcao] = useState(0);
  const [ordemAZMunicipioOpcao, setOrdemAZMunicipioOpcao] = useState(0);
  const [ordemAZDraeEscolaOpcao, setOrdemAZDraeEscolaOpcao] = useState(0);
  const [ordemAZDirecEscolaOpcao, setOrdemAZDirecEscolaOpcao] = useState(0);


  let dadosConsultadosOriginais = dadosConsultados;

  useEffect(() => {
    if (opcao === 'escola-mais-alimentacao'
      || opcao === 'escola-pague-predial'
      || opcao === 'escola-pague-ordinario') {
      if (filteredDirecs.length > 0
        || filteredEscolas.length > 0
        || filteredDraes.length > 0) {

        if (filteredDirecs.length > 0) {
          const dados = dadosEmTela.filter((escola) => {
            return filteredDirecs.some(selected => {
              return selected.value == escola.direc;
            });
          });
          setDadosEmTela((prevState) => ({
            ...prevState,
            dadosEmTela: dados
          }));
        }

        if (filteredDraes.length > 0) {
          const dados = dadosEmTela.filter((escola) => {
            return filteredDraes.some(selected => {
              return selected.value == escola.drae;
            });
          });
          setDadosEmTela((prevState) => ({
            ...prevState,
            dadosEmTela: dados
          }));
        }

        if (filteredEscolas.length > 0) {
          const dados = dadosConsultados.filter((escola) => {
            return filteredEscolas.some(selected => {
              return selected.value == escola.nome;
            });
          });
          setDadosEmTela((prevState) => ({
            ...prevState,
            dadosEmTela: dados
          }));
        }


      } else {
        setDadosEmTela((prevState) => ({
          ...prevState,
          dadosEmTela: dadosConsultadosOriginais
        }));

      }
    } else if (opcao == 'direc') {
      if (filteredDirecs.length > 0) {
        const dados = dadosConsultados.filter((direc) => {
          return filteredDirecs.some(selected => {
            return selected.value == direc.nome;
          });
        });
        setDadosEmTela((prevState) => ({
          ...prevState,
          dadosEmTela: dados
        }));
      } else {
        setDadosEmTela((prevState) => ({
          ...prevState,
          dadosEmTela: dadosConsultadosOriginais
        }));
      }
    } else if (opcao == 'drae') {
      if (filteredDraes.length > 0) {
        const dados = dadosConsultados.filter((direc) => {
          return filteredDraes.some(selected => {
            return selected.value == direc.nome;
          });
        });
        setDadosEmTela((prevState) => ({
          ...prevState,
          dadosEmTela: dados
        }));
      } else {
        setDadosEmTela((prevState) => ({
          ...prevState,
          dadosEmTela: dadosConsultadosOriginais
        }));
      }
    } else {
      setDadosEmTela((prevState) => ({
        ...prevState,
        dadosEmTela: dadosConsultadosOriginais
      }));
    }

  }, [filteredEscolas, filteredDraes, filteredDirecs, opcao]);

  function organizarSaldoTotalPeloMaior() {
    let teste;
    if (saldoTotalOpcao === 0) {
      setSaldoTotalOpcao(1)
      teste = [...dadosConsultados].sort((a, b) => {
        return (b.saldoAppFinanceira + b.saldoContaCorrente) - (a.saldoAppFinanceira + a.saldoContaCorrente);
      })
    } else {
      setSaldoTotalOpcao(0);
      teste = [...dadosConsultados].sort((a, b) => {
        return (a.saldoAppFinanceira + a.saldoContaCorrente) - (b.saldoAppFinanceira + b.saldoContaCorrente);
      })
    }
    setDadosEmTela((prevState) => ({
      ...prevState,
      dadosEmTela: teste
    }));
  }

  function organizarNomePorOrdemAlfabetica() {
    let AZ;
    if (ordemAZNomeOpcao === 0) {
      setOrdemAZNomeOpcao(1);
      AZ = [...dadosConsultados].sort((a, b) => {
        if (a.nome && b.nome) {
          return a.nome.localeCompare(b.nome, undefined, { numeric: true });
        }
      });
    } else {
      setOrdemAZNomeOpcao(0);
      AZ = [...dadosConsultados].sort((a, b) => {
        if (a.nome && b.nome) {
          return b.nome.localeCompare(a.nome, undefined, { numeric: true });
        }
      }
      );
    }

    setDadosEmTela((prevState) => ({
      ...prevState,
      dadosEmTela: AZ
    }));
  }

  function organizarMunicipioPorOrdemAlfabetica() {
    let AZ;
    if (ordemAZMunicipioOpcao === 0) {
      setOrdemAZMunicipioOpcao(1);
      AZ = [...dadosConsultados].sort((a, b) => {
        if (a.municipio && b.municipio) {
          return a.municipio.localeCompare(b.municipio, undefined, { numeric: true });
        }
      });
    } else {
      setOrdemAZMunicipioOpcao(0);
      AZ = [...dadosConsultados].sort((a, b) => {
        if (a.municipio && b.municipio) {
          return b.municipio.localeCompare(a.municipio, undefined, { numeric: true });
        }
      }
      );
    }

    setDadosEmTela((prevState) => ({
      ...prevState,
      dadosEmTela: AZ
    }));
  }

  function organizarNomeDraeEscolaPorOrdemAlfabetica() {
    let AZ;
    if (ordemAZDraeEscolaOpcao === 0) {
      setOrdemAZDraeEscolaOpcao(1);
      AZ = [...dadosConsultados].sort((a, b) => {
        if (a.drae && b.drae) {
          return a.drae.localeCompare(b.drae, undefined, { numeric: true });
        }
      });
    } else {
      setOrdemAZDraeEscolaOpcao(0);
      AZ = [...dadosConsultados].sort((a, b) => {
        if (a.drae && b.drae) {
          return b.drae.localeCompare(a.drae, undefined, { numeric: true });
        }
      }
      );
    }

    setDadosEmTela((prevState) => ({
      ...prevState,
      dadosEmTela: AZ
    }));
  }


  function organizarNomeDirecEscolaPorOrdemAlfabetica() {
    let AZ;
    if (ordemAZDirecEscolaOpcao === 0) {
      setOrdemAZDirecEscolaOpcao(1);
      AZ = [...dadosConsultados].sort((a, b) => {
        if (a.direc && b.direc) {
          return a.direc.localeCompare(b.direc, undefined, { numeric: true });
        }
      });
    } else {
      setOrdemAZDirecEscolaOpcao(0);
      AZ = [...dadosConsultados].sort((a, b) => {
        if (a.direc && b.direc) {
          return b.direc.localeCompare(a.direc, undefined, { numeric: true });
        }
      }
      );
    }

    setDadosEmTela((prevState) => ({
      ...prevState,
      dadosEmTela: AZ
    }));
  }


  const titulosDirec = [
    "ID",
    "Direc",
    "Município",
    "Saldo conta corrente",
    "Saldo aplicação",
    "Saldo total",
  ];
  const titulosEscola = [
    "ID",
    "Escola",
    "CNPJ",
    "Direc",
    "Drae",
    "Município",
    "Saldo conta corrente",
    "Saldo aplicação",
    "Saldo total",
  ];
  const titulosDrae = [
    "ID",
    "Drae",
    "Município",
    "Saldo conta corrente",
    "Saldo aplicação",
    "Saldo total",
  ];

  // calcula o total de saldo da conta corrente e da aplicação financeira
  function calcularTotal(saldo, getTotal) {
    const total = dadosEmTela?.reduce((acc, obj) => {
      if (!isNaN(obj[saldo])) {
        return acc + obj[saldo];
      } return acc
    }, 0);
    getTotal(total);
  }

  useEffect(() => {
    if (dadosEmTela?.length > 0) {
      calcularTotal("saldoContaCorrente", getTotalContaCorrente);
      calcularTotal("saldoAppFinanceira", getTotalAplicacao);
    }
  }, [dadosEmTela, opcao]);

  const renderHeaderCell = (titulo, index) => (
    <T.TabelaHeaderCell width={titulo === "ID" && "4rem"} key={index}>

      {titulo === "Saldo total" ? (
        <>
          <button onClick={() => organizarSaldoTotalPeloMaior()}
            style={{ backgroundColor: "transparent", border: "none", color: "#1D8241" }}>
            {saldoTotalOpcao === 0 ? (<FaArrowTrendUp />) : (<FaArrowTrendDown />)}
          </button>{titulo}
        </>
      ) : titulo === 'Município' ? (
        <>
          <button onClick={() => organizarMunicipioPorOrdemAlfabetica(titulo)}
            style={{ backgroundColor: "transparent", border: "none", color: "#1D8241" }}>
            {ordemAZMunicipioOpcao === 0 ? (<FaArrowUpZA />) : (<FaArrowDownZA />)}
          </button>{titulo}
        </>) : (
        opcao === 'escola-mais-alimentacao' || opcao === 'escola-pague-predial' || opcao === 'escola-pague-ordinario' ? (
          titulo === "Escola" ? (
            <>
              <button onClick={() => organizarNomePorOrdemAlfabetica(titulo)}
                style={{ backgroundColor: "transparent", border: "none", color: "#1D8241" }}>
                {ordemAZNomeOpcao === 0 ? (<FaArrowUpZA />) : (<FaArrowDownZA />)}
              </button>{titulo}
            </>)
            : titulo === "Drae" ? (<>
              <button onClick={() => organizarNomeDraeEscolaPorOrdemAlfabetica(titulo)}
                style={{ backgroundColor: "transparent", border: "none", color: "#1D8241" }}>
                {ordemAZDraeEscolaOpcao === 0 ? (<FaArrowUpZA />) : (<FaArrowDownZA />)}
              </button>{titulo}
            </>) : titulo === "Direc" ? (<>
              <button onClick={() => organizarNomeDirecEscolaPorOrdemAlfabetica(titulo)}
                style={{ backgroundColor: "transparent", border: "none", color: "#1D8241" }}>
                {ordemAZDirecEscolaOpcao === 0 ? (<FaArrowUpZA />) : (<FaArrowDownZA />)}
              </button>{titulo}
            </>) : titulo) : titulo === "Drae" || titulo === "Direc" ? (
              <>
                <button onClick={() => organizarNomePorOrdemAlfabetica(titulo)}
                  style={{ backgroundColor: "transparent", border: "none", color: "#1D8241" }}>
                  {ordemAZNomeOpcao === 0 ? (<FaArrowUpZA />) : (<FaArrowDownZA />)}
                </button>{titulo}
              </>
            ) : titulo
      )}
    </T.TabelaHeaderCell>
  )

  const renderRow = (linha, index) => (
    <T.TabelaRow key={index}>
      <T.TabelaDataCell width={'4rem'}>{index + 1}</T.TabelaDataCell>
      <T.TabelaDataCell>{linha.nome}</T.TabelaDataCell>
      {(opcao === 'escola-mais-alimentacao' || opcao === 'escola-pague-predial' || opcao === 'escola-pague-ordinario') && (
        <>
          <T.TabelaDataCell>{linha.cnpj}</T.TabelaDataCell>
          <T.TabelaDataCell>{linha.direc}</T.TabelaDataCell>
          <T.TabelaDataCell>{linha.drae}</T.TabelaDataCell>
        </>
      )}
      <T.TabelaDataCell>{linha.municipio}</T.TabelaDataCell>
      <T.TabelaDataCell>
        {renderCurrencyCell(linha.saldoContaCorrente)}
      </T.TabelaDataCell>
      <T.TabelaDataCell>
        {renderCurrencyCell(linha.saldoAppFinanceira)}
      </T.TabelaDataCell>
      <T.TabelaDataCell>
        {renderCurrencyCell(
          Number(linha.saldoAppFinanceira) + Number(linha.saldoContaCorrente)
        )}
      </T.TabelaDataCell>
    </T.TabelaRow>
  );

  const renderCurrencyCell = (value) =>
    !isNaN(value)
      ? value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })
      : "R$ 0,00";

  return (
    <>
      <T.Tabela>
        <T.TabelaHeader>
          <T.TabelaRow>
            {opcao === 'direc'
              ? titulosDirec.map(renderHeaderCell)
              : opcao === 'drae'
                ? titulosDrae.map(renderHeaderCell)
                : opcao === 'escola-mais-alimentacao' || opcao === 'escola-pague-predial' || opcao === 'escola-pague-ordinario'
                  ? titulosEscola.map(renderHeaderCell)
                  : null}
          </T.TabelaRow>
        </T.TabelaHeader>
        <T.TabelaBody>
          {(opcao === 'direc' || opcao === 'drae') && dadosConsultados?.length > 0
            ? dadosEmTela?.map(renderRow)
            : (opcao === 'escola-mais-alimentacao'
              || opcao === 'escola-pague-predial'
              || opcao === 'escola-pague-ordinario') &&
              dadosConsultados?.length > 0
              ? dadosEmTela?.map(renderRow)
              : "Clique em uma opção acima para consultar"}
        </T.TabelaBody>
      </T.Tabela>
    </>
  );
}

export default Table;
