
import Consultar from "../../pages/Relatorios/Table/Consultas.js";
export class ConsultaRelatorio {

    async execute(tipo, entidades, token, cancelToken, onProgress) {

        const fetchData = async () => {
            try {
                const resultadoConsulta = await Consultar({
                    tipo,
                    entidades,
                    token,
                    cancelToken,
                    onProgress,
                })
                return resultadoConsulta;
            } catch (error) {
                console.log(error)
                alert("Erro ao consultar dados: ", error)
            }
        }
        const result = await fetchData()
        return result
    }
}

