import * as React from "react";
import { FooterStyle, Text } from './Footer.style.jsx'

export default function Footer() {
  return (
    <>
      <FooterStyle>
        <Text><strong style={{ fontWeight: '600' }}>® Grupo DEV</strong> - GRUPO DE PROCESSAMENTO DE DADOS</Text>
        <Text style={{ fontSize: "12px", marginTop: "4px" }}>Versão 2.0</Text>
      </FooterStyle>
    </>
  )
}