import axios from "axios";

export async function verificarPermissoesDirec(dados, nomePermissao, contasPermissao, direcId) {

  if (contasPermissao !== null) {
    if (nomePermissao === "DIREC") {
      const direcsPorId = await buscarDirecPeloId(dados, direcId);
      const unicaDirecLista = [];
      unicaDirecLista.push(direcsPorId);
      return unicaDirecLista;
    } else if (
      nomePermissao === "FEE" ||
      nomePermissao === "ADMIN" ||
      nomePermissao === "CORE"
    ) {
      const todasDirecs = await buscarDirecs(dados);
      return todasDirecs.data;
    }
  } else {
    return null;
  }
}

async function buscarDirecPeloId(dados, direcId) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/direc/buscarPorId/${direcId}`, {
      headers: { authorization: `Bearer ${dados.usuario.token}` },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarDirecs(dados) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/direc/buscarAtivos`, {
      headers: { authorization: `Bearer ${dados.usuario.token}` },
    })
    .then((res) => {
      return res.data;
    })

    .catch((error) => {
      console.error(error);
    });
}
