import React, { useState } from "react";
import { ButtonStyle, AlignButton, IconMenu } from "./Button.style.jsx";
import { MdWork, MdSchool, MdAutoGraph, MdBorderColor } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { useMyPermissoesContext } from "../../../hooks/permissoes/MyPermissoesContext.jsx";

export default function ButtonsMenu({ setBotaoSelecionado, esconderMenu, setIsSelected, isSelected }) {

  const { permissoes, direcsPermitidas, escolasPermitidas, draesPermitidas } = useMyPermissoesContext();

  const dados = JSON.parse(sessionStorage.getItem("dados"));

  return (
    <>
      {direcsPermitidas && direcsPermitidas.length > 0 ?
        <ButtonStyle
          isSelected={isSelected === 'DIREC'}
          onClick={() => {
            setIsSelected('DIREC');
            setBotaoSelecionado('DIREC');
          }}
        >
          <AlignButton esconderMenu={esconderMenu}>
            <IconMenu><MdAutoGraph /></IconMenu>
            {esconderMenu ? 'DIREC' : null}
          </AlignButton>
        </ButtonStyle >
        : null
      }

      {
        escolasPermitidas && escolasPermitidas.length > 0 ?
          <ButtonStyle
            isSelected={isSelected === 'ESCOLA'
            }
            onClick={() => {
              setIsSelected('ESCOLA');
              setBotaoSelecionado('ESCOLA');
            }}
          >
            <AlignButton esconderMenu={esconderMenu}>
              <IconMenu><MdSchool /></IconMenu>
              {esconderMenu ? 'ESCOLA' : null}
            </AlignButton>
          </ButtonStyle >
          : null}

      {
        draesPermitidas && draesPermitidas.length > 0 ?
          <ButtonStyle
            isSelected={isSelected === 'DRAE'}
            onClick={() => {
              setIsSelected('DRAE');
              setBotaoSelecionado('DRAE');
            }}
          >
            <AlignButton esconderMenu={esconderMenu}>
              <IconMenu><MdWork /></IconMenu>
              {esconderMenu ? 'DRAE' : null}
            </AlignButton>
          </ButtonStyle >
          : null}

      {
        dados.usuario?.permissoes[0]?.nome === 'ADMIN' ?
          <ButtonStyle
            isSelected={isSelected === 'ADM'
            }
            onClick={() => {
              setIsSelected('ADM');
              setBotaoSelecionado('ADM');
            }}
          >
            <AlignButton esconderMenu={esconderMenu}>
              <IconMenu> <MdBorderColor /></IconMenu>
              {esconderMenu ? 'ADM' : null}
            </AlignButton>
          </ButtonStyle >
          : null}

      {
        dados.usuario.relatorio === true ?
          <ButtonStyle
            isSelected={isSelected === 'RELATÓRIO'
            }
            onClick={() => {
              setIsSelected('RELATÓRIO');
              setBotaoSelecionado('RELATÓRIO');
            }}
          >
            <AlignButton esconderMenu={esconderMenu}>
              <IconMenu><HiOutlineDocumentReport /></IconMenu>
              {esconderMenu ? 'RELATÓRIO' : null}
            </AlignButton>
          </ButtonStyle >
          : null
      }
    </>
  );
}