import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Menu from "../../components/Menu/Menu";
import { Container, BodyStyle, ContainerMenuEConteudo, ContainerHeaderEConteudo } from "./Home.style.jsx";
import Escola from "../Escola/Escola.jsx";
import Drae from "../Drae/Drae.jsx";
import Admin from "../Adm/Adm.jsx";
import Direc from "../Direc/Direc.jsx";
import Relatorio from "../Relatorios/Relatorio.jsx";
import lock from "../../assets/lock.svg";

import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";
import { useMyRelatorioContext } from "../../hooks/MyRelatorioContext.jsx";
import { HeaderTitle } from "../Relatorios/Relatorio.style.jsx";

function Layout({ nome }) {

  const dados = JSON.parse(sessionStorage.getItem("dados"));

  const [botaoSelecionado, setBotaoSelecionado] = useState("");

  const {
    verificarPermissoesDoUsuario,
    direcsPermitidas, draesPermitidas
  } = useMyPermissoesContext();


  //É feito a requisição dos dados no banco de dados a partir da permissão do usuário
  useEffect(() => {
    if (dados?.usuario?.permissoes) {
      verificarPermissoesDoUsuario(dados)
    }
  }, []);

  const [esconderMenu, setEsconderMenu] = useState(false);
  if (dados !== null) {
    return (
      <>

        <Container esconderMenu={esconderMenu} relatorio={botaoSelecionado === "RELATORIO" ? true : false}>

          <ContainerMenuEConteudo>
            <Menu
              nome={dados.usuario.nome}
              setBotaoSelecionado={setBotaoSelecionado}
              esconderMenu={esconderMenu}
              setEsconderMenu={setEsconderMenu} />
            {/*HEADER + BODY*/}
            <ContainerHeaderEConteudo>
              {botaoSelecionado != "" ? null : <Header esconderMenu={esconderMenu} titulo={nome} />}
              <BodyStyle
                relatorio={botaoSelecionado === "RELATORIO" ? true : false}
              >
                {botaoSelecionado ? <HeaderTitle title={botaoSelecionado} /> : null}

                {botaoSelecionado === "DIREC" ? (
                  <Direc />
                ) : botaoSelecionado === "DRAE" ? (
                  <Drae />
                ) : botaoSelecionado === "ESCOLA" ? (
                  <Escola />
                ) : botaoSelecionado === "ADM" ? (
                  <Admin />
                ) : botaoSelecionado === "RELATÓRIO" && dados.usuario.token ? (
                  <Relatorio
                    escolas={direcsPermitidas}
                    draes={draesPermitidas}
                    direcs={direcsPermitidas}
                  />
                ) : (
                  <div style={{ fontWeight: "semibold", display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                    Selecione uma opção no menu para começar. Explore as funcionalidades disponíveis na barra de navegação no lado esquerdo da tela.
                  </div>

                )}
              </BodyStyle >
            </ContainerHeaderEConteudo>
          </ContainerMenuEConteudo>
          <Footer />
        </Container >

      </>
    );
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "5%", width: "100vw" }}>
        <h1>Usuário não logado</h1>
        <Link to="/"> Clique aqui para acessar a página de login</Link>
        <img src={lock} />
      </div>
    );
  }
}

export default Layout;
