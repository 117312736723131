import { useForm } from "react-hook-form";
import axios from "axios";
import { Button } from "../../../components/globalComponents.style";
import * as S from "../style/form.style.jsx";
import { ContainerForm } from "../style/form.style.jsx";

const EscolaForm = ({ edicao, draes, direcs, url, atual, fecharPopUp }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: atual,
  });

  const escola = [
    "ativo",
    "convenio",
    "cnpj",
    "nome",
    "agencia",
    "draeId",
    "direcId",
    "fee",
    "conta",
    "municipio",
  ];

  const atualizar = async (atualizado) => {
    //atualizado.permissao = atualizado.permissao.filter(Boolean);
    const response = sessionStorage.getItem("dados"); // Substitua com o seu token de acesso

    try {
      edicao ? await axios.put(`${url}`, atualizado,
        { headers: { authorization: `Bearer ${JSON.parse(response).usuario.token}` } }) :
        await axios.post(`${url}`, atualizado,
          { headers: { authorization: `Bearer ${JSON.parse(response).usuario.token}` } })
      fecharPopUp();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContainerForm>
      <div>
        <S.Label style={{ fontSize: "150%", marginBottom: "0.5rem" }}>
          {edicao ? "Atualizar ESCOLA" : "Cadastrar nova ESCOLA"}
        </S.Label>
        <p style={{ color: "#6C6C6C", fontWeight: "bold" }}>Informações</p>
        <p style={{ color: "#6C6C6C", fontSize: "80%", marginTop: "2%" }}>
          Cadastre as informações da ESCOLA ao lado
        </p>
      </div>
      <S.Form onSubmit={handleSubmit(atualizar)}>
        <S.Colunas
          style={{
            width: "80%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginTop: "1.5rem",
          }}
        >
          {escola.map((campo, index) => {
            if (campo !== "id" && campo !== "ativo") {
              return (
                <S.Teste
                  key={index}
                  style={{
                    width: "calc(50% - 0.75rem)",
                    marginBottom: "1.5rem",
                  }}
                >
                  {campo === "draeId" ? (
                    <S.LabeledSelect
                      label="Drae"
                      type="text"
                      name="draeId"
                      {...register("draeId")}
                      id="draeId"
                    >
                      {draes &&
                        draes.map((drae, index) => {
                          return (
                            <option key={index} value={drae.id}>
                              {drae.nome}
                            </option>
                          );
                        })}
                    </S.LabeledSelect>
                  ) : campo === "direcId" ? (
                    <S.LabeledSelect
                      label="Direc"
                      type="text"
                      name="direcId"
                      {...register("direcId")}
                      id="direcId"
                    >
                      {direcs &&
                        direcs.map((direc, index) => {
                          return (
                            <option key={index} value={direc.id}>
                              {direc.nome}
                            </option>
                          );
                        })}
                    </S.LabeledSelect>
                  ) : (
                    <S.LabeledInput
                      label={campo.charAt(0).toUpperCase() + campo.slice(1)}
                      type="text"
                      name={campo}
                      {...register(campo)}
                    />
                  )}
                </S.Teste>
              );
            }
            return null;
          })}
        <Button style={{ width: "100%", height: "1rem" }}>
            Adicionar
          </Button>
        </S.Colunas>
      </S.Form>
    </ContainerForm>
  );
};

export default EscolaForm;
