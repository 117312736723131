/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FormConsulta from "../../components/FomularioConsulta/formConsulta.jsx";
import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";


function Direc() {
  const { permissoes, direcsPermitidas,
  } = useMyPermissoesContext();


  if (direcsPermitidas !== null) {
    return (
      <>
        {direcsPermitidas.length > 0 ? (<FormConsulta entidades={direcsPermitidas} />) : (<h1>Você não tem permissão para acessar essa página</h1>)}
      </>
    );
  }
}

export default Direc;
