import Rotas from './routes/Routes';
import styled from 'styled-components';
import { Toaster } from 'sonner';

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  font-family: 'Rawline', sans-serif;
`

function App() {
  return (
    <AppContainer>
      <Toaster
        richColors
        position="top-right"
        visibleToasts={3}
        toastOptions={{
          style: { padding: "0.8rem"},
          duration: 2000,
          cancelButtonStyle: { display: "flex", backgroundColor: "#ff0000", borderRadius: "0.5rem" },
        }} />
      <Rotas />
    </AppContainer>
  );
}

export default App;
