import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { TableBoxContainer, TableContainer } from "../style/Adm.style.jsx";
import { useMyPermissoesContext } from "../../../hooks/permissoes/MyPermissoesContext.jsx";
import AdicionarPopUp from "../popUps/AdicionarPopUp.jsx";
import { FaEdit } from "react-icons/fa";
import UsuarioService from "../../../services/UsuarioService.ts";
import PermissoesService from "../../../services/PermissoesService.ts";

export function Usuario() {
  const dados = sessionStorage.getItem("dados");

  const [usuarios, setUsuarios] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNovo, setIsOpenNovo] = useState(false);
  const [atual, setAtual] = useState(null);

  const [loading, setLoading] = useState(false);

  const { draesPermitidas, direcsPermitidas } =
    useMyPermissoesContext();

  const usuarioService = new UsuarioService();
  const permissoesService = new PermissoesService();

  const listarUsuarios = async () => {
    setLoading(true)
    usuarioService.listarUsuarios().then((response) => {
      setUsuarios(response.data);
      setLoading(false)
    })
  };

  const listarPermissoes = async () => {
    setLoading(true)
    permissoesService.listarPermissoes().then((response) => {
      setPermissoes(response.data);
      setLoading(false)
    })
  };

  useEffect(() => {
    listarUsuarios();
    listarPermissoes();
  }, []);

  function fecharPopUp() {
    listarUsuarios();
    setIsOpen(false);
    setIsOpenNovo(false);
  }

  const alterar = useCallback((itemAtual) => {
    setIsOpen(true);
    setAtual(itemAtual);
  }, []);

  function adicionarNovo() {
    setIsOpenNovo(true);
  }

  return ( <>
  {loading ? <div>loading</div> : (<TableBoxContainer>
      <TableContainer>
        <div className="utilsBox">
          <input className="input" placeholder="Pesquisar..." />
          <button className="button2" onClick={adicionarNovo}>
            + USUÁRIO
          </button>
        </div>

        {isOpen && (
          <AdicionarPopUp
            atual={atual}
            draes={draesPermitidas}
            direcs={direcsPermitidas}
            permissoes={permissoes}
            fecharPopUp={fecharPopUp}
            tipo={"usuario"}
            edicao={true}
          />
        )}

        {isOpenNovo && (
          <AdicionarPopUp
            fecharPopUp={fecharPopUp}
            tipo={"usuario"}
            draes={draesPermitidas}
            direcs={direcsPermitidas}
            permissoes={permissoes}
            edicao={false}
          />
        )}
        <tr>
          <td colSpan="8" className="tabletitle" style={{ padding: "1%" }}>
            Relação de USUÁRIOS
          </td>
        </tr>
        <table>
          <thead>

            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th>Email</th>
              <th>CPF</th>
              <th>Setor</th>
              <th>Telefone</th>
              <th>Relatório</th>
              <th>Permissões</th>
              <th>Editar</th>
            </tr>
          </thead>

          <tbody>
            {usuarios.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.nome}</td>
                <td>{row.email}</td>
                <td>{row.cpf}</td>
                <td>{row.setor ? row.setor : "Não aplica"}</td>
                <td>{row.telefone}</td>
                <td>{row.relatorio ? 'Acesso' : 'Sem acesso'}</td>
                <td>
                  {row.permissoes?.map((data, index) => (
                    <span key={index}>{data.permissao.nome}, </span>
                  ))}
                </td>
                <td>
                  <button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => alterar(row)}>
                    <FaEdit size={20} color="#0A5987" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </TableBoxContainer> )}
    </>
  );
}

export default Usuario;
