import React from "react";
import { HeaderStyle, Title } from "./Header.style.jsx";

export default function Header({ esconderMenu }) {
  return (
    <HeaderStyle esconderMenu={esconderMenu}>
      <Title>SVS - Sistema de Verificação de Saldos
      </Title>
    </HeaderStyle>
  );
}
