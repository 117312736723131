import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { toast } from 'sonner';

// Define a configuração de fonte uma vez
pdfMake.vfs = pdfFonts.pdfMake.vfs;



function geraExtratoPDF(data, entidade) {
    // Mapeia os dados do extrato para o formato esperado pelo pdfMake
    const array1 = ['a', 'b', 'c'];
    const dadosFIMapeados = array1.map((element, item) => {
        const fillColor = item % 2 === 0 ? '#FFFFFF' : '#DDDDDD';

        if (element === 'a') {
            return {
                // Cria um objeto com os dados que serão exibidos na primeira linha da tabela
                stack: [
                    {
                        text: `${data.data.extrato.dataSaldoAnterior}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10
                    },
                    { text: 'SALDO ANTERIOR', width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: `${data.data.extrato.valorSaldoAnterior.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, width: 'auto', fontSize: 10, fillColor: fillColor },
                    { text: '', width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: '', width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: `${data.data.extrato.quantidadeCotaAnterior.toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6, })}`, width: 'auto', fontSize: 10, fillColor: fillColor, alignment: 'justify' },
                    { text: '', width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: '', width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },

                ],
                _minWidth: 101.162109375, // Ajuste conforme necessário
                _maxWidth: 101.162109375, // Ajuste conforme necessário
            };

        }
        if (element === 'b') {
            const lancamentos = [];
            data.data.extrato.listaLancamentosExtrato.forEach((dado2, item) => {
                const fillColor = item % 2 === 0 ? '#DDDDDD' : '#FFFFFF';

                // Cria um objeto com os dados que serão exibidos nas linha do meio da tabela
                lancamentos.push({
                    stack: [
                        { text: `${dado2.dataLancamento}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                        { text: `${dado2.descricao}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                        { text: `${dado2.valorLancamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, width: 'auto', fillColor: fillColor, fontSize: 10 },
                        { text: `${(dado2.valorIR + dado2.valorPrejuizo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', }))}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                        { text: `${dado2.valorIOF.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                        { text: `${dado2.quantidadeCota.toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6, })}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                        { text: `${dado2.valorCota.toLocaleString('pt-BR', { minimumFractionDigits: 9, maximumFractionDigits: 9, })}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                        { text: `${dado2.saldoCotas.toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6, })}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    ],
                    _minWidth: 101.162109375, // Ajuste conforme necessário
                    _maxWidth: 101.162109375, // Ajuste conforme necessário
                });
            });

            return lancamentos;
        }
        if (element === 'c') {
            return {
                // Cria um objeto com os dados que serão exibidos na ultima linha da tabela
                stack: [
                    { text: `${data.data.extrato.dataSaldoMesAnterior}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: 'SALDO ATUAL', width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: `${data.data.extrato.valorSaldoMesAnterior.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, width: 'auto', fillColor: fillColor, fontSize: 10 },
                    { text: '', width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: '', width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: `${data.data.extrato.quantidadeCotaMesAnterior.toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6, })}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: '', width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },
                    { text: `${data.data.extrato.quantidadeCotaMesAnterior.toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6, })}`, width: 'auto', fillColor: fillColor, alignment: 'justify', fontSize: 10 },

                ],
                _minWidth: 101.162109375, // Ajuste conforme necessário
                _maxWidth: 101.162109375, // Ajuste conforme necessário
            };

        }
        return null;
    })
    const docDefinition = {
        // ... outras configurações ...
        content: [
            {    // titulo
                stack: [
                    { text: `${entidade.nome} - ${entidade.municipio}`, style: 'header', alignment: 'center', fontSize: 12, margin: [0, 0, 0, 5] },
                    { text: `CNPJ: ${entidade.cnpj} - Ag: ${entidade.agencia} Conta: ${entidade.conta} `, style: 'header', alignment: 'center', fontSize: 12, margin: [0, 0, 0, 5] },
                    { text: `Extrato Fundo de Investimento: convênio - ${entidade.convenio}`, style: 'header', alignment: 'center', fontSize: 12, margin: [0, 0, 0, 10] },
                ],
            },
            {  // tabela
                table: {
                    headerRows: 1,
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'], // Ajuste conforme necessário
                    body: [
                        [
                            { text: 'Data', style: 'tableHeader', fontSize: 10 },
                            { text: 'Histórico', style: 'tableHeader', fontSize: 10 },
                            { text: 'Valor', style: 'tableHeader', fontSize: 10 },
                            { text: 'Valor IRPrej. Comp.', style: 'tableHeader', fontSize: 10 },
                            { text: 'Valor IOF', style: 'tableHeader', fontSize: 10 },
                            { text: 'Quantidade cotas', style: 'tableHeader', fontSize: 10 },
                            { text: 'Valor cota', style: 'tableHeader', fontSize: 10 },
                            { text: 'Saldo cotas', style: 'tableHeader', fontSize: 10 },
                        ],
                        //cria as linahas da tabela
                        ...[].concat(...dadosFIMapeados.map(entry => Array.isArray(entry) ? entry : [entry])).map(entry => entry.stack),
                    ],
                    layout: 'lightHorizontalLines',
                    alignment: 'center', // Centraliza a tabela na folha
                },
            },

            { // Resumo do mês
                stack: [
                    { text: 'Resumo do mês', style: 'header', alignment: 'left', fontSize: 10, margin: [5, 10, 0, 0] },
                ],
            },

            {  // tabela
                table: {
                    widths: ['auto', 'auto'], // Defina o número de colunas aqui
                    body: [
                        // Linha 1
                        [{ text: 'SALDO ANTERIOR', fontSize: 10 }, { text: `${data.data.extrato.valorSaldoAnterior.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, fontSize: 10 }],
                        // Linha 2
                        [{ text: 'APLICAÇÕES (+)', fontSize: 10 }, { text: `${data.data.extrato.valorTotalAplicadoPeriodo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, fontSize: 10 }],
                        // Linha 3
                        [{ text: 'RESGATES (-)', fontSize: 10 }, { text: `${data.data.extrato.valorTotalResgatadoPeriodo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, fontSize: 10 }],
                        // Linha 4
                        [{ text: 'RENDIMENTO BRUTO (+)', fontSize: 10 }, { text: `${data.data.extrato.valorRendimentoBrutoPeriodo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, fontSize: 10 }],
                        // Linha 5
                        [{ text: 'IMPOSTO DE RENDA (-)', fontSize: 10 }, { text: `${data.data.extrato.valorTotalIRPeriodo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, fontSize: 10 }],
                        // Linha 6
                        [{ text: 'IOF (-)', fontSize: 10 }, { text: `${data.data.extrato.valorTotalIOFPeriodo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, fontSize: 10 }],
                        // Linha 7
                        [{ text: 'RENDIMENTO LIQUIDO (-)', fontSize: 10 }, { text: `${data.data.extrato.valorRendimentoLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, fontSize: 10 }],
                        // Linha 8
                        [{ text: 'SALDO ATUAL', fontSize: 10 }, { text: `${data.data.extrato.valorSaldoMesAnterior.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', })}`, fontSize: 10 }],
                    ],
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                },
            },
            { // Valor da Cota
                stack: [
                    { text: 'Valor da cota', style: 'header', alignment: 'left', fontSize: 10, margin: [5, 10, 0, 0] },
                ],
            },

            {  // tabela
                table: {
                    widths: ['auto', 'auto'], // Defina o número de colunas aqui
                    body: [
                        // Linha 1
                        [{ text: `${data.data.extrato.dataAfericaoValorCota.toLocaleString('pt-BR', { minimumFractionDigits: 9, maximumFractionDigits: 9, })}`, fontSize: 10 }, { text: `${data.data.extrato.valorCota}`, fontSize: 10 }],
                        // Linha 2
                        [{ text: `${data.data.extrato.dataUltimaCotacaoCota.toLocaleString('pt-BR', { minimumFractionDigits: 9, maximumFractionDigits: 9, })}`, fontSize: 10 }, ''],

                    ],
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                },
            },
            { // Rentabilidade
                stack: [
                    { text: 'Rentabilidade', style: 'header', alignment: 'left', fontSize: 10, margin: [5, 10, 0, 0] },
                ],
            },

            {  // tabela
                table: {
                    widths: ['auto', 'auto'], // Defina o número de colunas aqui
                    body: [
                        // Linha 1
                        [{ text: 'No mês', fontSize: 10 }, { text: `${data.data.extrato.valorRentabilidadeMes.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4, })}`, fontSize: 10 }],
                        // Linha 2
                        [{ text: 'No ano', fontSize: 10 }, { text: `${data.data.extrato.valorRentabilidadeAno.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4, })}`, fontSize: 10 }],
                        // Linha 3
                        [{ text: 'Último 12 meses', fontSize: 10 }, { text: `${data.data.extrato.valorRentabilidadeResgateTotal.toLocaleString('pt-BR', { minimumFractionDigits: 4, maximumFractionDigits: 4, })}`, fontSize: 10 }],

                    ],
                    layout: 'lightHorizontalLines',
                    alignment: 'center',
                },
            },


        ],
    };
    // Gera o PDF
    return docDefinition;
}


async function extratoPDF({dadosFI, entidade}) {
    // Gera todas as Promises para os PDFs

    if (!dadosFI || !dadosFI.length || dadosFI.length === 0 || !entidade) {
        toast.warning('Não há dados da consulta para gerar PDF.');
        return;
    }
    const pdfPromises = dadosFI?.map((dado) => {
        return geraExtratoPDF(dado, entidade);
    });

    // Aguarda todas as Promises serem resolvidas
    const pdfs = await Promise.all(pdfPromises);

    // Abre cada PDF
    pdfs?.forEach((pdf) => {
        pdfMake.createPdf(pdf).download(); // Alteração aqui
    });

}

export default extratoPDF;