import React, { useState, useEffect, useCallback } from "react";
import { TableBoxContainer, TableContainer } from "../style/Adm.style.jsx";
import { useMyPermissoesContext } from "../../../hooks/permissoes/MyPermissoesContext.jsx";
import AdicionarPopUp from "../popUps/AdicionarPopUp.jsx";
import { FaEdit } from "react-icons/fa";
import DraesService from "../../../services/DraesService.ts";

export function Drae() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNovo, setIsOpenNovo] = useState(false);
  const [atual, setAtual] = useState(null);

  const { draesPermitidas, setDraesPermitidas } =
    useMyPermissoesContext();

  const draeService = new DraesService();
  
  const listar = async () => {
    draeService.listar().then((response) => {
      setDraesPermitidas(response.data);
    })
  };

  useEffect(() => {
    listar();
  }, []);

  function fecharPopUp() {
    listar();
    setIsOpen(false);
    setIsOpenNovo(false);
  }

  const alterar = useCallback((itemAtual) => {
    setIsOpen(true);
    setAtual(itemAtual);
  }, []);

  function adicionarNovo() {
    setIsOpenNovo(true);
  }

  return (
    <TableBoxContainer>
      <TableContainer>
        <div className="utilsBox">
          <input className="input" placeholder="Pesquisar..." />
          <button className="button2" onClick={adicionarNovo}>
            + DRAES
          </button>
        </div>

        {isOpen && (
          <AdicionarPopUp
            atual={atual}
            direcs={draesPermitidas}
            fecharPopUp={fecharPopUp}
            tipo='drae'
            edicao={true}
          />
        )}

        {isOpenNovo && (
          <AdicionarPopUp
            fecharPopUp={fecharPopUp}
            tipo='drae'
            direcs={draesPermitidas}
            edicao={false}
          />
        )}
  <tr>
          <td colSpan="8" className="tabletitle" style={{ padding: "1%" }}>
            Relação de DRAE
          </td>
        </tr>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>FEE</th>
              <th>Município</th>
              <th>Convênio</th>
              <th>CNPJ</th>
              <th>Agência</th>
              <th>Conta</th>
              <th>Editar</th>
            </tr>
          </thead>

          <tbody>
            {draesPermitidas.map((row, index) => (
              <tr key={index}>
                <td>{row.nome}</td>
                <td>{row.fee}</td>
                <td>{row.municipio}</td>
                <td>{row.convenio}</td>
                <td>{row.cnpj}</td>
                <td>{row.agencia}</td>
                <td>{row.conta}</td>
                <td>
                <button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => alterar(row)}>
                    <FaEdit size={20} color="#0A5987" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </TableBoxContainer>
  );
}

export default Drae;
