import React from "react";
import PopUp from "../../../components/PopUp/popUp";
import FormAdicionar from "../forms/formEditarEAdicionar";
import { FaTimes } from "react-icons/fa";

import "../style/form.css";

function AdicionarPopUp({
  edicao,
  draes,
  direcs,
  escolas,
  atual,
  fecharPopUp,
  tipo,
  permissoes,
  usuarios,
}) {
  return (
    <>
      <PopUp fecharPopUp={fecharPopUp} height={"90vh"} width={"100%"}>
        <button
          className="cancelButton"
          onClick={fecharPopUp}
        >
          <FaTimes size={20}/>
        </button>

        <FormAdicionar
          edicao={edicao}
          atual={atual}
          fecharPopUp={fecharPopUp}
          tipo={tipo}
          permissoes={permissoes}
          draes={draes}
          direcs={direcs}
          escolas={escolas}
          usuarios={usuarios}
        />
      </PopUp>
    </>
  );
}

export default AdicionarPopUp;
