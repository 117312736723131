import axios from "axios";

export async function verificarPermissoesDrae(dados, nomePermissao, contasPermissao, draeId) {
  if (contasPermissao !== null) {
    if (nomePermissao === "DRAE") {
      const unicaDrae = await buscarDraePeloId(dados, draeId);
      const unicaDraeLista = [];
      unicaDraeLista.push(unicaDrae.data);
      return unicaDraeLista;
    } else if (
      nomePermissao === "SUASE" ||
      nomePermissao === "ADMIN" ||
      nomePermissao === "FEE"
    ) {
      const todasDraes = await buscarDraes(dados);
      return todasDraes.data;
    }
  } else {
    return null;
  }
}

async function buscarDraes(dados) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/drae/buscarAtivos`, {
      headers: { authorization: `Bearer ${dados.usuario.token}` },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
}

async function buscarDraePeloId(dados, id) {
  return axios
    .get(`${process.env.REACT_APP_FEE}/drae/buscarPorId/${id}`, {
      headers: { authorization: `Bearer ${dados.usuario.token}` }
    })
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      console.error(error);
    });
}
