/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FormConsulta from "../../components/FomularioConsulta/formConsulta.jsx";
import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";

function Drae() {

  const { permissoes, draesPermitidas,
  } = useMyPermissoesContext();

  if (draesPermitidas !== null) {
    return (
      <>
        {draesPermitidas.length > 0 ? (<FormConsulta entidades={draesPermitidas} />) : (<h1>Você não tem permissão para acessar essa página</h1>)}
      </>
    );
  }
}

export default Drae;
