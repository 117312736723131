import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  font-family: "Rawline", sans-serif;
  align-items: ${(props) => (props.relatorio ? "start" : "center")};
`;

export const BodyStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: auto;
  
  overflow-x: hidden;
  background-color: #f7f8fa;
`;

export const ContainerMenuEConteudo = styled.div`
  height: 95%;
  display: flex;
  width: 100%;
  border-top: 5px solid #3aa25f;
  overflow-x: hidden;
`;


export const ContainerHeaderEConteudo = styled.div`
 height: 100%;
  display: flex; 
  width: 100%; 
  flex-direction: column; 
  overflow-x: hidden;
  
`;
