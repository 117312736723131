import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { TableBoxContainer, TableContainer } from "../style/Adm.style.jsx";
import AdicionarPopUp from "../popUps/AdicionarPopUp.jsx";
import { FaEdit } from "react-icons/fa";
import PermissoesService from "../../../services/PermissoesService.ts";

export function Permissao() {
  const dados = sessionStorage.getItem("dados");
  const [permissoes, setPermissoes] = useState([]);
  const permissoesService = new PermissoesService();

  const listarPermissoes = async () => {
    permissoesService.listarPermissoes().then((response) => {
      setPermissoes(response.data);
    })
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNovo, setIsOpenNovo] = useState(false);
  const [atual, setAtual] = useState(null);

  function fecharPopUp() {
    setIsOpen(false);
    setIsOpenNovo(false);
  }

  const alterar = useCallback((itemAtual) => {
    setIsOpen(true);
    setAtual(itemAtual);
  }, []);

  function adicionarNovo() {
    setIsOpenNovo(true);
  }

  useEffect(() => {
    listarPermissoes();
  }, []);

  return (
    <TableBoxContainer>
      <TableContainer>
        <div className="utilsBox">
          <input className="input" placeholder="Pesquisar..." />
          <button className="button2" onClick={adicionarNovo}>
            + PERMISSÃO
          </button>
        </div>

        {isOpen && (
          <AdicionarPopUp
            atual={atual}
            permissoes={permissoes}
            fecharPopUp={fecharPopUp}
            tipo={"permissao"}
            edicao={true}
          />
        )}

        {isOpenNovo && (
          <AdicionarPopUp
            fecharPopUp={fecharPopUp}
            tipo={"permissao"}
            permissoes={permissoes}
            edicao={false}
          />
        )}
        <tr>
          <td colSpan="8" className="tabletitle" style={{ padding: "1%" }}>
            Relação de PERMISSÕES
          </td>
        </tr>
        <table>
          <thead style={{backgroundColor:"white"}}>

            <tr>
              <th>Id</th>
              <th>Nome</th>
              <th>Programa Direc</th>
              <th>Programa Drae</th>
              <th>Programa Escola</th>
              <th>Editar</th>
            </tr>
          </thead>

          <tbody>
            {permissoes.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.nome}</td>
                <td>{row.programaDirec}</td>
                <td>{row.programaDrae}</td>
                <td>{row.programaEscola}</td>
                <td>
                  <button style={{ backgroundColor: "transparent", border: "none" }} onClick={() => alterar(row)}>
                    <FaEdit size={20} color="#0A5987" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </TableBoxContainer>
  );
}

export default Permissao;
