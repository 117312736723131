import { useForm } from "react-hook-form";
import axios from "axios";
import { Button } from "../../../components/globalComponents.style.jsx";
import * as S from "../style/form.style.jsx";
import { ContainerForm } from "../style/form.style.jsx";

const PermissaoForm = ({ edicao, url, atual, fecharPopUp }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: atual,
  });

  const permissao = ["nome", "programaDirec", "programaDrae", "programaEscola"];

  const atualizar = async (atualizado) => {
    const response = sessionStorage.getItem("dados"); // Substitua com o seu token de acesso
    let config = null;

    if (!edicao) {
      config = {
        method: "post",
        url: `${url}`,
        data: atualizado,
        headers: {
          authorization: `Bearer ${JSON.parse(response).usuario.token}`,
        },
      };
    } else {
      config = {
        method: "put",
        url: `${url}`,
        data: atualizado,
        headers: {
          authorization: `Bearer ${JSON.parse(response).usuario.token}`,
        },
      };
    }

    try {
      await axios(config);
      fecharPopUp();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContainerForm>
      <div>
        <S.Label style={{ fontSize: "150%", marginBottom: "0.5rem" }}>
          {edicao ? "Atualizar permissão" : "Cadastrar nova permissão"}
        </S.Label>
      </div>
      <S.Form onSubmit={handleSubmit(atualizar)}>
        <S.Colunas
          style={{
            width: "80%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <S.Teste style={{ width: "50%", height: "100%", gap: "1.5rem" }}>
            {permissao.map((campo, index) => {
              if (campo !== "id") {
                return (
                  <S.LabeledInput
                    label={campo.charAt(0).toUpperCase() + campo.slice(1)}
                    type="text"
                    name={campo}
                    key={index}
                    {...register(campo)}
                  />
                );
              }
              return null;
            })}
          </S.Teste>
          <Button style={{ width: "50%", height: "2rem" }}>Adicionar</Button>
        </S.Colunas>
      </S.Form>
    </ContainerForm>
  );
};

export default PermissaoForm;
