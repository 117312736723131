import styled from "styled-components";
import { Spinner } from 'react-bootstrap';

export const Form = styled.form`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 100%;
`;

export const CamposObrigatorio = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: fit-content;
  width: 48%;
  height: 100%;
  font-size: 1rem;
  justify-content: space-between;
  align-items: center;
`;

export const Saldo = styled.div`
  display: flex;
  position: relative;
  height: fit-content;
  width: 100%;
  padding-bottom: 2%;
  flex-direction: column;
  font-size: 1rem;
  font-family: "Rawline", sans-serif;
  font-weight: bold;
`;

export const Label = styled.label`
  display: flex;
  font-size: ${(props) => (props.maior ? "" : "18px")};
  font-family: "Rawline", sans-serif;
  font-weight: bold;
  width: fit-content;
`;

export const LabelTitle = styled.label`
  display: flex;
  font-size: 1rem;
  font-weight: semibold;
  width: fit-content;
  height: fit-content;
  color: #000;
`;

export const ContainerSaldo = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 40%;
  height: 100%;
  right: 0;
  padding: 2%;
  flex-direction: column;
  color: #000;

`;

export const ContainerFormConsulta = styled.div`
display: flex;
        height: 20%;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 1%;
          resize: vertical;
          position: relative;
          
  `;
export const ContainerFormConsulta2 = styled.div`
  display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          position: relative;
          padding: 1%;
          gap: 1%;
          background-color: white;
          border: none;
          overflow: auto;
`;

export const LabelSaldo = ({ titulo, valor, loading, ...props }) => {
  const valorFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);

  return (
    <Saldo>
      <div style={{ width: "100%", backgroundColor: "#f0f0f0" }}>
        <Label>{titulo}</Label>
      </div>

      <div style={{ width: "100%", backgroundColor: "#fafafa", color: "#4554CB" }}>
        {loading ? (
          valor !== undefined ? (
            <Label maior>
              {valor > 0 || (valor !== "" && valor !== undefined)
                ? `${valorFormatado}`
                : "Valor não disponível"}
            </Label>
          ) : (
            <Spinner animation="" role="">
              <span className="">Aguarde...</span>
            </Spinner>
          )
        ) : (
          "-"
        )}
      </div>
    </Saldo>
  );
};

const FormStyles = {
  CamposObrigatorio,
  Form,
  Saldo,
  Label,
  LabelTitle,
  ContainerSaldo,
  LabelSaldo
};

export default FormStyles;