import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import { MyRelatorioContextProvider } from "../hooks/MyRelatorioContext";
import { MyPermissoesContextProvider } from "../hooks/permissoes/MyPermissoesContext";

function Rotas() {
  return (
    <MyPermissoesContextProvider>
      <MyRelatorioContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/home" exact element={<Layout />} />
            <Route path="/" exact element={<Login />} />
          </Routes>
        </BrowserRouter>
      </MyRelatorioContextProvider>
    </MyPermissoesContextProvider>

  );
}

export default Rotas;
