/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import FormConsulta from "../../components/FomularioConsulta/formConsulta.jsx";
import { useMyPermissoesContext } from "../../hooks/permissoes/MyPermissoesContext.jsx";

function Escola() {
  const { permissoes, escolasPermitidas,
  } = useMyPermissoesContext();

  useEffect(() => {
  }, [escolasPermitidas])

  return (
    <>

      {escolasPermitidas.length > 0 ? (
        <FormConsulta entidades={escolasPermitidas} />
      ) : (
        <h1>Você não tem permissão para acessar essa página</h1>
      )}
    </>
  );

}

export default Escola;
